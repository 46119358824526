import {FC, useEffect, useState} from "react";
import moment from "moment";
import {useForm} from "react-hook-form";
import ModalDefault from "../../../components/ModalDefault/ModalDefault";
import {useTrackingEvents} from "../../../providers/Tracking/TrackingEventListProvider";
import InputMask from "react-input-mask";
import getMask from "../../../utils/GetMask";

interface ModalEventListFilterProps {
    show: boolean;
    onClose?: any;
}

type FormValues = {
    [key: string]: string;
}

const ModalEventListFilter: FC<ModalEventListFilterProps> = ({show, onClose}) => {
    const [startDate, setStartDate] = useState<any>(moment(new Date()).format('01/MM/YYYY'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).format('DD/MM/YYYY'));
    const {setEventsParam, eventsParam, eventListOptions} = useTrackingEvents();

    const defaultValues = eventListOptions.reduce((acc, option) => {
        acc[option.value] = "";
        return acc;
    }, {startDate: "", endDate: ""} as FormValues);

    const {reset, handleSubmit, register} = useForm<FormValues>({defaultValues});

    const onSubmit = async (data: FormValues) => {
        data.startDate = moment(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        data.endDate = moment(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

        setEventsParam({...eventsParam, ...data, page: 1});
        onClose(false);
    }

    useEffect(() => {
        if (show) {
            reset(defaultValues);
        }
    }, [show]);


    const getInputField = (option: any) => {
        const mask = getMask(option.mask);
        if (mask) {
            return (
                <InputMask
                    mask={mask}
                    className="form-control"
                    {...register(option.value)}
                />
            );
        }
        return (
            <input
                type="text"
                className="form-control"
                {...register(option.value)}
            />
        );
    };

    return (
        <ModalDefault
            show={show}
            onClose={onClose}
            title="Filtrar Eventos"
        >
            <div data-testId={'ModalEventListFilter'}>
                <div className="row">
                    {eventListOptions.map(option => (
                        <div key={option.value} className="col-12 col-md-6">
                            <label htmlFor={option.value} className="form-control-label">{option.label}</label>
                            <div className="form-group">
                                {getInputField(option)}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="startDate" className="form-control-label">Data de Início</label>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="endDate" className="form-control-label">Data de Fim</label>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                <button onClick={handleSubmit(onSubmit)} className="btn btn-primary">Filtrar</button>
            </div>
        </ModalDefault>
    );
}

export default ModalEventListFilter;