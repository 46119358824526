import React, {FC, useState} from 'react';
import './EventList.scss';
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import {useTrackingEvents} from "../../../providers/Tracking/TrackingEventListProvider";
import SkeletonDynamicTable from "../../../components/SkeletonDynamicHeadersTable/SkeletonDynamicHeadersTable";
import QuickFilter from "../../../components/QuickFilter/QuickFilter";
import AppPagination from "../../../components/AppPagination/AppPagination";
import moment from "moment/moment";
import FormatCpf from "../../../utils/FormatCpf";
import FormatPhone from "../../../utils/FormatPhone";
import {OrigemIcon} from "../../../utils/OrigemIcon";
import ModalEventListFilter from "../Components/ModalEventListFilter";

interface EventsProps {
}


const EventList: FC<EventsProps> = () => {
    const {
        isLoading,
        eventList,
        pages,
        handleExcel,
        handlePaginate,
        isLoadingFile,
        setEventsParam,
        eventListOptions,
    } = useTrackingEvents();
    const [selectedItem, setSelectedItem] = useState<any>();
    const [value, setValue] = useState<string>("");
    const [showModalFilter, setShowModalFilter] = useState<boolean>(false);
    const headers = ["Nome/CPF", "Campanha", "App Origem", "Content", "Medium", "Source", "Term", "Data"];
    const renderContent = () => {
        if (isLoading) {
            return <SkeletonDynamicTable headers={headers}/>;
        }

        if (eventList?.length === 0) {
            return (
                <div className='text-sm text-center pb-4'>
                    <div className='text-center'>Não existem eventos para serem exibidos.</div>
                </div>
            )
        }

        return (
            <>
                <div className="ps-3">
                    <QuickFilter
                        isButtonDisabled={isLoading}
                        options={eventListOptions}
                        onSubmit={() => {
                            setEventsParam({[selectedItem?.value]: value});
                        }}
                        value={value}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        setValue={setValue}
                    />
                </div>
                <div className="table-responsive p-o">
                    <table className="table table-flush" id="datatable-search">
                        <thead className="thead-light">
                        <tr>
                            <th>Nome/CPF</th>
                            <th>Campanha</th>
                            <th>App Origem</th>
                            <th>Content</th>
                            <th>Medium</th>
                            <th>Source</th>
                            <th>Term</th>
                            <th>Data</th>
                        </tr>
                        </thead>
                        <tbody>
                        {eventList?.map((event, index) => {
                            return (
                                <tr key={index} className="text-xs font-weight-bold">
                                    <td className='text-wrap'>
                                        {event.name || '---'}
                                        <br/>
                                        CPF: <b>{FormatCpf(event.cpf)}</b>
                                        <br/>
                                        Fone: <b>{event.cellphone ? FormatPhone(event.cellphone) : '---'}</b>
                                    </td>
                                    <td>{event.campaignName}</td>
                                    <td><i className={`${OrigemIcon(event.appOrigem || '')}`}/></td>
                                    <td>{event.content}</td>
                                    <td>{event.medium}</td>
                                    <td>{event.source}</td>
                                    <td>{event.term}</td>
                                    <td>{moment(event.created_at).format('DD/MM/YYYY HH:mm')}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    {(eventList?.length > 0 || (pages?.previous || 0) > 0) &&
                        <div>
                            <nav aria-label="...">
                                <AppPagination pages={pages} handlePaginate={handlePaginate}/>
                            </nav>
                        </div>
                    }
                </div>
            </>
        );

    }

    return (
        <PainelMasterPage title="Rastramento de Eventos">
            <div className="EventList" data-testid="EventList">
                <PageBase
                    title="Rastramento de Eventos"
                    subtitle='Rastreamento de eventos de campanhas de marketing'
                    hasFilter={true}
                    hasExcelPdf={true}
                    content={renderContent()}
                    handleExcel={handleExcel}
                    isLoadingFile={isLoadingFile}
                    handleFilter={() => setShowModalFilter(true)}
                />
            </div>
            <ModalEventListFilter
                show={showModalFilter}
                onClose={setShowModalFilter}
            />
        </PainelMasterPage>
    );
};

export default EventList;
