import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {SimulationsService} from "../../services/Simulations/SimulationsService";
import {SimulationsParamsModel} from "../../models/Simulations/SimulationsParamsModel";
import {usePaginate} from "../PaginateProvider";
import {ToastSettings} from "../../components/ToastSettings";
import {toast} from "react-toastify";

interface ContextProps {
    simulations: any[],
    simulationsParams: SimulationsParamsModel,
    setSimulationsParams: Dispatch<SetStateAction<SimulationsParamsModel>>,
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    isLoading: boolean,
    isLoadingFile: boolean,
    handleList: () => Promise<any>,
    handleExcel: () => Promise<any>,
}

export const SimulationsContext = createContext<ContextProps>({} as ContextProps);

interface SimulationsProviderProps {
    children: ReactNode
}

export const SimulationsProvider = ({children}: SimulationsProviderProps) => {
    const [simulations, setSimulations] = useState<any[]>([]);
    const [simulationsParams, setSimulationsParams] = useState<SimulationsParamsModel>({});
    const [error, setError] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);

    const location = useLocation();
    const service = new SimulationsService();
    const {params, setPagesPaginate} = usePaginate();

    const handleList = useCallback(async () => {
        if (!location.pathname.includes('/simulations/list')) {
            return false;
        }
        try {
            setIsLoading(true);
            const [_Response, _Error] = await service.list({
                ...simulationsParams, ...params
            });
            if (!!_Error) {
                setIsLoading(false);
                setError(_Error);
                return false;
            }

            setError('');
            setSimulations(_Response?.data);
            setPagesPaginate(_Response.pages);
            setIsLoading(false);

            return true;
        } catch (e: any) {
            console.warn(e);
            setIsLoading(false);
            setError(e);
            return false;
        }
    }, [location.pathname, simulationsParams, params]);

    const handleExcel = async () => {
        setError('');
        setIsLoadingFile(true);

        const [_response, _error] = await service.export({
            ...simulationsParams, ...params
        });

        if (_error) {
            setIsLoadingFile(false);
            ToastSettings(_error || 'Não foi possivel solicitar o relatório.', 'bottom-center', 'error');
            return false;
        }

        toast.success(_response?.message || 'Relatório gerado com sucesso!');
        setIsLoadingFile(false);
    };

    useEffect(() => {
        handleList().then();
    }, [handleList]);

    return (
        <SimulationsContext.Provider value={{
            simulations,
            simulationsParams,
            setSimulationsParams,
            isLoading,
            isLoadingFile,
            error,
            setError,
            handleList,
            handleExcel
        }}>
            {children}
        </SimulationsContext.Provider>
    );
}

export const useSimulations = () => useContext(SimulationsContext);