import React, {FC} from 'react';
import SkeletonTable from "../SkeletonTable/SkeletonTable";


interface TableSkeletonProps {
    headers: string[];
}

const SkeletonDynamicTable: FC<TableSkeletonProps> = ({headers}) => {
    const columnsLength = headers.length;
    return (
        <div className="table-responsive">
            <table className="table align-items-center table-flush">
                <thead className="thead-light">
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td colSpan={columnsLength}>
                        <SkeletonTable columns={columnsLength}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SkeletonDynamicTable;