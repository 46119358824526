import apiDashboard from "../ApiDashboard";

const DEFAULT_MESSAGE = 'Não foi possível realizar essa ação por favor tente mais tarde.';
const PREFIX_URL = 'api/dinheirosim/tracking/campaigns/';

export class TrackingCampaignService {
    async campaignList(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${PREFIX_URL}search`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async createEvent(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.put(`${PREFIX_URL}event/create`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async listEvent(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${PREFIX_URL}event/list`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async exportTrackingCampaign(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${PREFIX_URL}export/tracking`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }

    async exportCampaignEvent(params: any): Promise<any[]> {
        try {
            const response = await apiDashboard.post(`${PREFIX_URL}export/event`, params);
            return [response?.data, undefined];
        } catch (e) {
            return [undefined, DEFAULT_MESSAGE];
        }
    }
}