import {MaskEnum} from "../models/MaskEnum";

const getMask = (mask?: MaskEnum | string) => {
    const {PHONE, CPF} = MaskEnum;
    switch (mask) {
        case CPF:
            return "999.999.999-99";
        case PHONE:
            return "(99) 99999-9999";
        default:
            return "";
    }
};

export default getMask;