import {createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState} from "react";
import {TrackingCampaignService} from "../../services/TrackinCampaignEvents/TrackinCampaignEventService";
import {PagesPaginateModel} from "../../models/PagesPaginate";
import {ToastSettings} from "../../components/ToastSettings";
import {toast} from "react-toastify";
import {useAuth} from "../AuthProvider";
import {ProposalsTrackParam} from "../../models/ProposalsTrack/ProposalsTrackParams";
import {MaskEnum} from "../../models/MaskEnum";

interface ContextProps {
    eventList: any [],
    campaignList: any [],
    error: string,
    setError: Dispatch<SetStateAction<string>>,
    isLoading: boolean,
    setIsLoading: Dispatch<SetStateAction<boolean>>,
    pages: PagesPaginateModel,
    handleExcel: () => Promise<any>,
    handlePaginate: (pageNumber: number) => void,
    isLoadingFile: boolean,
    totalEvents: number,
    setTotalEvents: Dispatch<SetStateAction<number>>,
    eventsParam: ProposalsTrackParam,
    setEventsParam: Dispatch<SetStateAction<ProposalsTrackParam>>,
    eventListOptions: EventListOptions[],
}

export interface EventListOptions {
    value: string,
    label: string,
    mask?: MaskEnum,
}

export const TrackingEventsContext = createContext<ContextProps>({} as ContextProps);

export const TrackingEventListProvider = (props: any) => {
    const [pages, setPages] = useState<PagesPaginateModel>({} as PagesPaginateModel);
    const [eventsParam, setEventsParam] = useState<ProposalsTrackParam>({} as ProposalsTrackParam);
    const [totalEvents, setTotalEvents] = useState<number>(0);
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
    const [eventList, setEventList] = useState([]);
    const [campaignList, setCampaignList] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const service = new TrackingCampaignService();
    const {CanAccess} = useAuth();

    const eventListOptions = [
        {"value": "cpf", "label": "CPF", "mask": MaskEnum.CPF},
        {"value": "campaignName", "label": "Campanha"},
        {"value": "appOrigem", "label": "App Origem"},
        {"value": "content", "label": "Content"},
        {"value": "medium", "label": "Medium"},
        {"value": "source", "label": "Source"},
        {"value": "term", "label": "Term"},
    ];

    const loadEvents = useCallback(async () => {
        console.log(eventsParam);
        try {
            setIsLoading(true);
            setError('');

            const [_response, _error] = await service.listEvent(eventsParam);
            setEventList(_response.data);
            setTotalEvents(_response.pages?.total ?? 0);
            setPages(_response.pages);
            setIsLoading(false);
            if (!!_error) {
                setIsLoading(false);
                setError(_error);
                return false;
            }
            return true;
        } catch (e: any) {
            console.warn(e);
            setIsLoading(false);
            setError(e);
            return false;
        }
    }, [eventsParam]);

    const loadCampaigns = useCallback(async () => {
        try {
            setIsLoading(true);
            setError('');

            const [_response, _error] = await service.campaignList({});
            setCampaignList(_response.data);
            setIsLoading(false);
            if (!!_error) {
                setIsLoading(false);
                setError(_error);
                return false;
            }
            return true;
        } catch (e: any) {
            console.warn(e);
            setIsLoading(false);
            setError(e);
            return false;
        }
    }, []);

    const handleExcel = async () => {
        try {
            setError('');
            setIsLoadingFile(true);
            CanAccess('leads.ver_tudo.regra').then(async () => {
                const [_Response, _Error] = await service.exportCampaignEvent({});

                if (_Error) {
                    setIsLoadingFile(false);
                    ToastSettings(_Error || 'Não foi possível solicitar o relatório.', 'bottom-center', 'error');
                    return false;
                }

                toast.success(_Response?.message || 'Relatório gerado com sucesso!');
                return setIsLoadingFile(false);
            });
        } catch (e) {
            console.warn(e);
            return setIsLoadingFile(false);
        }
    };

    const handlePaginate = (pageNumber: number) => {
        setEventsParam(oldEventsParam => ({
            ...oldEventsParam,
            page: pageNumber
        }));
    };

    useEffect(() => {
        loadEvents().then();
    }, [eventsParam, loadEvents]);

    useEffect(() => {
        loadEvents().then();
        loadCampaigns().then();
    }, [loadEvents, loadCampaigns]);

    return (
        <TrackingEventsContext.Provider
            value={{
                eventList,
                campaignList,
                error,
                setError,
                isLoading,
                setIsLoading,
                pages,
                handleExcel,
                handlePaginate,
                isLoadingFile,
                totalEvents,
                setTotalEvents,
                eventsParam,
                setEventsParam,
                eventListOptions,
            }}>
            {props.children}
        </TrackingEventsContext.Provider>
    );
};

export const useTrackingEvents = () => useContext(TrackingEventsContext);