import React, {FC} from 'react';
import './CampaignList.scss';
import PainelMasterPage from "../../../components/PainelMasterPage/PainelMasterPage";
import PageBase from "../../../components/PageBase/PageBase";
import {useTrackingEvents} from "../../../providers/Tracking/TrackingEventListProvider";
import SkeletonDynamicTable from "../../../components/SkeletonDynamicHeadersTable/SkeletonDynamicHeadersTable";

interface CampaignListProps {
}

const CampaignList: FC<CampaignListProps> = () => {
    const {isLoading, campaignList} = useTrackingEvents();
    const headers = ["Campanha", "User ID", "Data"];
    const renderContent = () => {
        if (isLoading) {
            return <SkeletonDynamicTable headers={headers}/>;
        }

        if (campaignList?.length === 0) {
            return (
                <tr>
                    <td colSpan={3} className='text-center'>Não existem campanhas para serem exibidas.</td>
                </tr>
            )
        }

        return (
            <div className="table-responsive">
                <table className="table align-items-center table-flush">
                    <thead className="thead-light">
                    <tr>
                        <th>Campanha</th>
                        <th>User ID</th>
                        <th>Data</th>
                    </tr>
                    </thead>
                    <tbody>
                    {campaignList?.map((event, index) => {
                        return (
                            <tr key={index}>
                                <td>{event.name}</td>
                                <td>{event.userId}</td>
                                <td>{event.created_at || '---'}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );

    }

    return (
        <PainelMasterPage title="Rastramento de Campanhas">
            <PageBase
                title="Rastramento de Eventos"
                subtitle='Acompanhe as campanhas de marketing'
                // @ts-ignore
                content={renderContent()}
            />
        </PainelMasterPage>
    );
}

export default CampaignList;
