import React, {Dispatch, FC, SetStateAction} from 'react';
import './QuickFilter.scss';
import ReactSelect from "react-select";
import {customStyles} from "../../models/SelectCustomStyles";
import makeAnimated from "react-select/animated";
import {MaskEnum} from "../../models/MaskEnum";
import ReactInputMask from "react-input-mask";
import getMask from "../../utils/GetMask";

interface QuickFilterProps {
    options: { value: string, label: string, mask?: MaskEnum | null }[];
    onSubmit: any;
    selectedItem: { value: string, label: string, mask?: MaskEnum | null } | null,
    setSelectedItem: Dispatch<SetStateAction<{ value: string, label: string, mask?: MaskEnum | null } | null>>;
    value: string;
    setValue: Dispatch<SetStateAction<string>>;
    isButtonDisabled?: boolean;
}

const QuickFilter: FC<QuickFilterProps> = (
    {
        options,
        onSubmit,
        selectedItem,
        setSelectedItem,
        value,
        setValue,
        isButtonDisabled
    }) => {


    const animatedComponents = makeAnimated();

    const mask = () => getMask(selectedItem?.mask ?? '');

    return (<div className="QuickFilter" data-testid="QuickFilter">
            <div className='row'>
                <div className='col-3'>
                    <label htmlFor="">Filtro</label>
                    <ReactSelect
                        isMulti={false}
                        isClearable
                        isSearchable
                        options={options}
                        placeholder='Selecione...'
                        className='form-control p-0'
                        value={selectedItem}
                        defaultValue={selectedItem}
                        components={animatedComponents}
                        onChange={val => {
                            setValue("");
                            setSelectedItem(val)
                        }}
                        styles={customStyles}
                    />
                </div>
                <div className="col-3 form-group">

                    <label htmlFor="">Valor</label>
                    <ReactInputMask
                        mask={mask()}
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value)
                        }}
                        className='form-control'
                        type="text"
                    />
                </div>
                <div className="col form-group">
                    <label>&nbsp;</label>
                    <button
                        disabled={!selectedItem || !value || isButtonDisabled === true}
                        type="button"
                        className="btn btn-primary d-block"
                        onClick={() => {
                            // onSubmit({[selectedItem?.value]: value,});
                            onSubmit();
                        }}>
                        Buscar
                    </button>
                </div>
            </div>

        </div>
    );
}


export default QuickFilter;
